import { useApiManual } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'

import { AudienceFormOutput } from './types'

export const useCardAudienceSubmit = () => {
  const api = useActivationApi()
  const { requestThrows: linkAudiencesInternal } = useApiManual(api.linkAudiences)

  const linkAudiences = async ({ audiences, adGroupId }: {
    audiences: AudienceFormOutput['audiences']
    adGroupId: number
  }) => {
    await linkAudiencesInternal({
      adGroupId,
      links: audiences.map(audience => ({
        groupId: audience.groupId,
        audienceId: audience.excluded ? -audience.id : audience.id,
      })),
    })
  }

  return { linkAudiences }
}
