import { Ref, computed, toValue, watch } from 'vue'
import { RefOrGetter } from '@lasso/shared/types'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import {
  ChannelType,
  useActivationApi,
} from '@lasso/api-activation/activation'
import { createInjectionState } from '@lasso/shared/utils'
import { v4 as uuidv4 } from 'uuid'

import { getIsEndemicChannel } from '@lasso/shared-activation/shared'

import {
  AdGroupAudience,
  AudienceAccount,
  AudienceEngagement,
  CardAudienceFormInput,
} from './types'

export const [useProvideCardAudiences, useCardAudiences] = createInjectionState(({
  isCardEnabled,
  isDraft,
  isActive,
  adGroupId,
  initialValues,
  marketerId,
  accountId: adGroupAccountId,
  channelId,
  accounts,
  isSourceAdGroup,
  isDirectSold,
  useFieldModels,
  useFieldModelsStartingWith,
  useFieldArray,
  useFieldError,
  resetField,
  getId,
}: {
  isCardEnabled: RefOrGetter<boolean>
  isDraft: Readonly<Ref<boolean>>
  isActive: Readonly<Ref<boolean>>
  channelId: Readonly<Ref<ChannelType>>
  adGroupId: Readonly<Ref<number>>
  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
  accounts: Readonly<Ref<Record<string, AudienceAccount[]>>>
  initialValues: RefOrGetter<CardAudienceFormInput>
  isSourceAdGroup: Ref<boolean>
  isDirectSold: boolean
} & UseFormAddonsContext<CardAudienceFormInput>) => {
  const { isLassoTrigger } = useFieldModels(['isLassoTrigger'])

  const audience = useFieldModelsStartingWith('audience', [
    'audiences',
    'platformId',
    'accountId',
    'cpm',
    'newAudienceName',
    'automaticallyPushAudience',
  ])

  const lassoTrigger = useFieldModelsStartingWith('lassoTrigger', [
    'sourceTypeId',
    'sourceGroups',
    'triggerEngagementId',
    'engagements',
    'logicCondition',
  ])

  const setInitialAudiences = (audiences: AdGroupAudience[]) => {
    resetField('audience.audiences', { value: audiences })
  }

  const api = useActivationApi()

  const isDataOnly = computed(() => channelId.value === ChannelType.DATA_ONLY)
  const isEndemicChannel = computed(() => getIsEndemicChannel(channelId.value))
  const hideCpm = computed(() => isEndemicChannel.value)

  const platformAccounts = computed(() => {
    if (!audience.platformId.value) {
      return []
    }

    const platformAccounts = accounts.value[audience.platformId.value] ?? []

    return platformAccounts.map(account => ({
      label: `${account.id} - ${account.type === 'advertiser' ? 'Adv' : 'Mar'}-Level`,
      value: account.id,
    }))
  })

  const getInitialAudience = (id: number) => {
    return toValue(initialValues).audience.audiences.find(audience => audience.id === id) ?? null
  }

  const platformIdDisabled = computed(() => {
    const { platformId, accountId } = toValue(initialValues).audience

    return Boolean(platformId) && Boolean(accountId)
  })

  const availablePlatformIds = computed(() => Object.keys(accounts.value))

  const accountIdDisabled = computed(() => !audience.platformId.value || platformAccounts.value.length < 2)

  const { loading, onData, error, request } = useApi(
    api.getAdGroupAudiences,
    () => {
      return toValue(isCardEnabled)
        ? [{ adGroupId: adGroupId.value, paging: { pageSize: 999 } }]
        : null
    },
    { refetch: true },
  )

  onData(({ data }) => {
    setInitialAudiences(
      data?.map(item => ({
        id: item.id ?? 0,
        name: item.name ?? '',
        audienceType: item.audienceType!,
        uniqueRecords: item.uniqueRecords ?? 0,
        createdDate: item.createdDate ?? '',
        audienceTargetId: item.targetType !== undefined ? String(item.targetType) : '',
        displayCpm: item.displayCPM ?? 0,
        socialCpm: item.socialCPM ?? 0,
        groupId: item.groupId ?? 0,
        excluded: item.excluded ?? false,
        matchedDeviceRecords: item.matchedDeviceRecords ?? 0,
        linkedWithAdGroup: true,
        hasSegmentationFilter: false,
      })) ?? [],
    )
  })

  const audiencesError = useFieldError('audience.audiences')
  const engagementsArray = useFieldArray('lassoTrigger.engagements')

  const updateEngagement = (index: number, value: Partial<AudienceEngagement>) => {
    const engagement = engagementsArray.fields.value[index]

    if (engagement) {
      engagementsArray.update(index, { ...engagement.value, ...value })
    }
  }

  const addEngagement = () => {
    engagementsArray.push({
      id: uuidv4(),
      action: '',
      periodValue: null,
      period: null,
      quantity: null,
    })
  }

  const removeEngagement = (index: number) => {
    engagementsArray.remove(index)
  }

  watch(audience.platformId, () => {
    if (platformAccounts.value.length === 1) {
      audience.accountId.value = platformAccounts.value[0]!.value
    }
    else if (audience.accountId.value) {
      audience.accountId.value = ''
    }
  })

  watch([isDataOnly, isLassoTrigger], ([isDataOnly]) => {
    if (isDataOnly && audience.audiences.value.length > 0 && !isSourceAdGroup.value) {
      audience.audiences.value = []
    }
  })

  watch(channelId, () => {
    if (isDraft.value && audience.audiences.value.length > 0) {
      audience.audiences.value = []
    }
  })

  watch(lassoTrigger.sourceTypeId, () => {
    if (lassoTrigger.sourceGroups.value.length > 0) {
      lassoTrigger.sourceGroups.value = []
    }
  })

  return {
    isDataOnly,
    hideCpm,
    isDraft,
    isActive,
    adGroupId,
    marketerId,
    adGroupAccountId,
    channelId,
    loading,
    error,
    request,
    platformAccounts,
    platformIdDisabled,
    accountIdDisabled,
    availablePlatformIds,
    accounts,
    isLassoTrigger,
    isSourceAdGroup,
    isDirectSold,
    getId,
    getInitialAudience,
    audiencesError,
    ...audience,
    ...lassoTrigger,

    updateEngagement,
    addEngagement,
    removeEngagement,
  }
})
