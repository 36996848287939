<template>
  <FormSection v-if="sourceTypeId" title="Select trigger source(s)" width="100%">
    <Box flex>
      <ErrorState
        v-if="validationError"
        error="Failed to validate source ad groups"
        inline
        px="0"
        py="2"
        @retry="validateSourceAdGroupIds()"
      />
    </Box>

    <FormError :id="getId('lassoTrigger.sourceGroups')" pb="2" :errorText="sourceGroupsError" />

    <ErrorState v-if="errorAdGroups" :error="errorAdGroups" inline @retry="requestAdGroups()" />
    <Box v-else>
      <Skeleton v-if="loadingAdGroups" height="300px" />
      <SelectPanel v-else :empty="fetchedAdGroups.length === 0">
        <template #search>
          <SelectPanelInput v-model="searchQuery" />
        </template>

        <template #items>
          <SelectPanelVirtualScroller
            v-slot="{ item }"
            :items="fetchedAdGroups"
            keyField="adGroupID"
          >
            <SelectPanelItem
              :modelValue="isItemSelected(item.adGroupID)"
              :chip="item.adGroupID"
              :searchQuery="searchQuery"
              :label="item.adGroupName ?? ''"
              :disabled="!isDraft || noSourceAdGroups"
              singleLine
              @update:modelValue="handleSourceGroupSelection(item)"
            />
          </SelectPanelVirtualScroller>
        </template>

        <template #selectedItemsTitle>
          Selected audiences
        </template>

        <template v-if="selectedListItemsReverse.length > 0" #selectedItems>
          <Skeleton v-if="validatingAdGroups" height="300px" />
          <template v-else>
            <SelectPanelSelectedItem
              v-for="item in selectedListItemsReverse"
              :key="item.adGroupID"
              :label="item.adGroupName ?? ''"
              :disabled="!isDraft || noSourceAdGroups"
              @remove="handleSourceGroupRemove(item)"
            />
          </template>
        </template>
      </SelectPanel>
    </Box>
  </FormSection>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { refDebounced } from '@vueuse/core'
import {
  Box,
  ErrorState, FormError,
  FormSection,
  SelectPanel,
  SelectPanelInput,
  SelectPanelItem,
  SelectPanelSelectedItem,
  SelectPanelVirtualScroller,
  Skeleton,
} from '@lasso/luikit'
import { AdGroupSource, ChannelType, GetSourceAdGroupsQuery, useActivationApi } from '@lasso/api-activation/activation'
import { useApi, useApiManual } from '@lasso/shared/hooks'
import { objFromEntries } from '@lasso/shared/utils'

import { useCardAudiences } from '../useCardAudiences'

const api = useActivationApi()

const {
  marketerId,
  adGroupAccountId,
  channelId,
  isLassoTrigger,
  isDraft,
  sourceGroups,
  sourceTypeId,
  getId,
  cpm,
} = useCardAudiences()!

const searchQuery = ref('')
const searchQueryDebounced = refDebounced(searchQuery, 500)
const searchQueryValue = computed(() => searchQueryDebounced.value?.trim())

const getSourceAdGroupData = computed((): [GetSourceAdGroupsQuery] | null => {
  if (!isLassoTrigger.value || !marketerId.value || !adGroupAccountId.value || !sourceTypeId.value) {
    return null
  }

  return [{
    marketerId: marketerId.value,
    advertiserId: adGroupAccountId.value,
    campaignId: null,
    keyword: searchQueryValue.value,
    sourceType: sourceTypeId.value,
  }]
})

// TODO: useApiInfinite paging. Don't need to implement. All PROD data < 200 records (couple marketers).
// Most marketer / advertiser PROD data < 20 records.
const { loading: loadingAdGroups, data, error: errorAdGroups, request: requestAdGroups } = useApi(
  api.getSourceAdGroups,
  () => getSourceAdGroupData.value,
  { refetch: true },
)

const fetchedAdGroups = computed(() => data.value?.data ?? [])

const noSourceAdGroups = computed(() => !!sourceTypeId.value && !loadingAdGroups.value && !fetchedAdGroups.value.length)

const selectedSourceGroups = computed(() => {
  return fetchedAdGroups.value.filter(group => sourceGroups.value.includes(group.adGroupID?.toString() ?? ''))
})

const selectedListItemsReverse = computed(() => {
  return selectedSourceGroups.value.slice().reverse()
})

const selectedItemsMap = computed(() => {
  return objFromEntries(
    selectedSourceGroups.value.map(item => [item.adGroupID ?? '', item] as const),
  )
})

const isItemSelected = (id: number | undefined) => {
  return id !== undefined && Boolean(selectedItemsMap.value[id])
}

const handleSourceGroupRemove = (group: AdGroupSource) => {
  sourceGroups.value = sourceGroups.value.filter(id => id !== group.adGroupID?.toString())
}

const {
  request: validateSourceAdGroups,
  loading: validatingAdGroups,
  error: validationError,
} = useApiManual(api.getSourceAdGroupsValidate)

const sourceGroupsError = ref('')

const validateSourceAdGroupIds = async () => {
  if (!sourceGroups.value.length) {
    sourceGroupsError.value = ''
    return
  }

  const response = await validateSourceAdGroups({
    marketerId: marketerId.value,
    advertiserId: adGroupAccountId.value,
    adGroupIDs: sourceGroups.value?.map(id => Number(id)) ?? [],
  })

  if (!response) {
    return
  }

  if (!response.success) {
    sourceGroupsError.value = response.message ?? ''
  }
  else {
    const { emailMaxDataCPM, xandrMaxDataCPM } = response
    const dataCpm = channelId.value === ChannelType.EMAIL ? emailMaxDataCPM : xandrMaxDataCPM
    if (dataCpm) {
      cpm.value = Number((dataCpm).toFixed(2))
    }

    sourceGroupsError.value = ''
  }
}

const handleSourceGroupSelection = async (group: AdGroupSource) => {
  const adGroupId = group.adGroupID?.toString() ?? ''

  if (sourceGroups.value.includes(adGroupId)) {
    sourceGroups.value = sourceGroups.value.filter(id => id !== adGroupId)
  }
  else {
    sourceGroups.value = [...sourceGroups.value, adGroupId]
  }

  await validateSourceAdGroupIds()
}
</script>
