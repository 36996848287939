<template>
  <Box pb="4">
    <Typography variant="h6" color="textPrimary">
      Select platform
    </Typography>
  </Box>

  <FormControl
    v-if="availablePlatforms.length"
    :id="getId('audience.platformId')"
    hideLabel
  >
    <Box flex direction="row" class="gap-6" wrap="wrap">
      <RadioButton
        v-for="platform in availablePlatforms"
        :key="platform.id"
        v-model="platformId"
        name="platform"
        :value="platform.id"
        :title="platform.title"
        :disabled="platformIdDisabled"
      >
        <template v-if="platform.iconComponent" #icon>
          <Icon>
            <component :is="platform.iconComponent" />
          </Icon>
        </template>
      </RadioButton>
    </Box>
  </FormControl>

  <Alert v-else color="error" title="Something went wrong!" maxWidth="856px">
    In order to select a platform to push your audience to, please navigate
    to the advertiser’s
    <Link to="/admin/advertisers">
      Account Settings
    </Link>
    and save the Account ID for the platform.
  </Alert>

  <FormControl
    :id="getId('audience.accountId')"
    v-slot="{ error, id }"
    label="Account ID"
    width="336px"
    required
  >
    <Select
      :id="id"
      v-model="accountId"
      :error="error"
      :options="platformAccounts"
      :disabled="accountIdDisabled || platformIdDisabled"
      :hideArrow="accountIdDisabled"
      placeholder="Select"
    />
  </FormControl>

  <Box flex direction="col" spaceY="4">
    <Typography variant="h6" color="textPrimary">
      Customize audience name
    </Typography>

    <Box flex justify="start" spaceX="6">
      <FormControl :id="getId('audience.newAudienceName')" v-slot="{ id }" label="New audience name" width="160px" :fullWidth="false">
        <InputText :id="id" v-model="newAudienceName" :disabled="nameDisabled" />
      </FormControl>

      <FormControl :id="getId('audience.cpm')" v-slot="{ id }" label="Preferred Data CPM" width="160px" :fullWidth="false">
        <InputText :id="id" v-model="cpm" :name="id" currency :mask="decimalMask" />
      </FormControl>

      <Box mt="8">
        <FieldControl
          :id="getId('audience.automaticallyPushAudience')"
          v-slot="{ id }"
          label="Automatically push audience"
        >
          <Toggle :id="id" v-model="automaticallyPushAudience" size="xs" color="success" />
        </FieldControl>
      </Box>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import {
  Alert,
  Box,
  FieldControl,
  FormControl, Icon,
  InputText, Link,
  RadioButton,
  Select,
  Toggle,
  Typography,
} from '@lasso/luikit'
import { decimalMask } from '@lasso/shared/consts'
import { computed } from 'vue'

import { useCardAudiences } from './useCardAudiences'

import IconCustomFacebookOutlined from '~icons/custom/facebook-outlined'
import IconCustomLassoFilled from '~icons/custom/lasso-filled'
import IconCustomLinkedinOutlined from '~icons/custom/linkedin-outlined'
import IconCustomSnapchatOutlined from '~icons/custom/snapchat-outlined'
import IconCustomTikTokOutlined from '~icons/custom/tik-tok-outlined'
import IconCustomXOutlined from '~icons/custom/x-outlined'
import IconCustomXandrOutlined from '~icons/custom/xandr-outlined'

const {
  isDataOnly,
  isActive,

  automaticallyPushAudience,
  accountId,
  newAudienceName,
  platformId,
  cpm,
  accountIdDisabled,
  platformAccounts,
  platformIdDisabled,
  availablePlatformIds,

  getId,
} = useCardAudiences()!

const nameDisabled = computed(() => isDataOnly.value && isActive.value)

const platforms = [
  {
    id: 'lasso',
    title: 'Lasso',
    iconComponent: IconCustomLassoFilled,
  },
  {
    id: 'twitter',
    title: 'X',
    iconComponent: IconCustomXOutlined,
  },
  {
    id: 'facebook-instagram',
    title: 'Facebook',
    iconComponent: IconCustomFacebookOutlined,
  },
  {
    id: 'linked-in',
    title: 'Linkedin',
    iconComponent: IconCustomLinkedinOutlined,
  },
  {
    id: 'tik-tok',
    title: 'TikTok',
    iconComponent: IconCustomTikTokOutlined,
  },
  {
    id: 'snapchat',
    title: 'Snapchat',
    iconComponent: IconCustomSnapchatOutlined,
  },
  {
    id: 'xandr-dmp',
    title: 'Xandr DMP',
    iconComponent: IconCustomXandrOutlined,
  },
  // removed in ACT-386
  // {
  //   id: 'trade-desk-dmp',
  //   title: 'The Trade Desk',
  //   iconComponent: icons.IconTradeDesk,
  // },
  {
    id: 'liveramp',
    title: 'Liveramp',
  },
  {
    id: 'external-dsp',
    title: 'Xandr Curate',
    iconComponent: IconCustomXandrOutlined,
  },
]

const availablePlatforms = computed(() => {
  return platforms.filter(item => availablePlatformIds.value.includes(item.id))
})
</script>
