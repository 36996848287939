<template>
  <Box py="2">
    <Box v-if="loading" py="2" px="4">
      <Typography variant="overline1" color="textSecondary" uppercase>
        searching...
      </Typography>
    </Box>
    <template v-else-if="audiences.length">
      <Box flex alignItems="center" py="1.5" px="4" minHeight="36px">
        <Typography variant="overline1" color="textSecondary" uppercase>
          {{ searchQuery ? 'Results' : 'Recent' }}
        </Typography>
      </Box>

      <AudienceSearchListItem
        v-for="(audience, index) in audiences"
        :key="index"
        :audience="audience"
        :active="isActive(audience)"
        :highlight="searchQuery"
        @click="onSelect(audience)"
      />
    </template>
    <Box v-else py="2" px="4">
      <Typography variant="overline1" color="textSecondary" uppercase>
        no results found
      </Typography>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { Box, Typography } from '@lasso/luikit'

import { AdGroupAudience } from '../types'

import AudienceSearchListItem from './AudienceSearchListItem.vue'

const props = defineProps<{
  audiences: AdGroupAudience[]
  selectedIds: AdGroupAudience['id'][]
  loading: boolean
  searchQuery: string
}>()

const emits = defineEmits<{
  select: [AdGroupAudience]
}>()

const onSelect = (audience: AdGroupAudience) => {
  emits('select', audience)
}

const isActive = (audience: AdGroupAudience) => props.selectedIds.includes(audience.id)
</script>
