<template>
  <Box flex direction="col" spaceY="6" alignItems="start">
    <FormSection title="Select trigger source type">
      <FormControl :id="getId('lassoTrigger.sourceTypeId')" :disabled="!isDraft" hideLabel>
        <Box flex alignItems="center" justify="start" spaceX="2">
          <FieldControl id="sourceTypeProgrammatic" v-slot="{ id }" label="Programmatic" width="120px" :disabled="!isDraft">
            <Radio
              :id="id"
              v-model="sourceTypeIdInternal"
              :value="AdGroupSourceType.PROGRAMMATIC"
              :disabled="!isDraft"
              name="sourceTypeProgrammatic"
            />
          </FieldControl>

          <FieldControl id="sourceTypeEmail" v-slot="{ id }" label="Email" :disabled="!isDraft">
            <Radio
              :id="id"
              v-model="sourceTypeIdInternal"
              :value="AdGroupSourceType.EMAIL"
              :disabled="!isDraft"
              name="sourceTypeEmail"
            />
          </FieldControl>
        </Box>
      </FormControl>
    </FormSection>

    <SourceGroupSelector v-if="sourceTypeId" />

    <FormSection v-if="sourceTypeId" title="Select engagement">
      <FormControl
        :id="getId('lassoTrigger.triggerEngagementId')"
        v-slot="{ id }"
        label="Engagement"
        width="220px"
        required
        :disabled="!sourceTypeId || !isDraft"
      >
        <Select
          :id="id"
          :modelValue="triggerEngagementId || ''"
          :options="triggerEngagementIdOptions"
          :disabled="!isDraft"
          placeholder="Select"
          width="160px"
          @update:modelValue="changeTriggerAction"
        />
      </FormControl>
    </FormSection>

    <CustomTriggerEngagements v-if="triggerEngagementId === AdGroupTriggerEngagement.CUSTOM_DYNAMIC" />
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  Box,
  FieldControl,
  FormControl,
  FormSection,
  Radio,
  Select,
  SelectOptionType,
} from '@lasso/luikit'
import { AdGroupSourceType, AdGroupTriggerEngagement } from '@lasso/api-activation/activation'
import { objValues } from '@lasso/shared/utils'

import { useCardAudiences } from '../useCardAudiences'
import { SupportedAdGroupTriggerEngagement } from '../types'

import SourceGroupSelector from './SourceGroupSelector.vue'
import CustomTriggerEngagements from './CustomTriggerEngagements.vue'

const {
  isDraft,
  triggerEngagementId,
  engagements,
  sourceTypeId,
  addEngagement,
  getId,
} = useCardAudiences()!

const sourceTypeIdInternal = computed({
  get: () => sourceTypeId.value ?? '',
  set: (value) => {
    sourceTypeId.value = value || null
  },
})

const triggerEngagementIdMap: { [K in SupportedAdGroupTriggerEngagement]: SelectOptionType<K> } = {
  [AdGroupTriggerEngagement.VIEW]: {
    value: AdGroupTriggerEngagement.VIEW,
    label: 'View',
  },
  [AdGroupTriggerEngagement.CLICK]: {
    value: AdGroupTriggerEngagement.CLICK,
    label: 'Click',
  },
  [AdGroupTriggerEngagement.CUSTOM_DYNAMIC]: {
    value: AdGroupTriggerEngagement.CUSTOM_DYNAMIC,
    label: 'Custom',
  },
}
const triggerEngagementIdOptions = objValues(triggerEngagementIdMap)

const changeTriggerAction = (newTriggerAction: AdGroupTriggerEngagement) => {
  engagements.value = []
  triggerEngagementId.value = newTriggerAction

  if (triggerEngagementId.value === AdGroupTriggerEngagement.CUSTOM_DYNAMIC) {
    addEngagement()
  }
}
</script>

<style scoped>
.triggers-card {
  @apply border border-divider rounded-xl py-[29px] px-6 gap-4 pr-10;
}

.engagement-delete {
  @apply absolute right-4 top-4;
}
</style>
