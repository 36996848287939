import { AudienceExternalAccount } from './types'

export enum AudienceTargetId {
  HealthcareProvider = 1,
  PatientConsumer = 2,
  ProfessionalNonHcp = 3,
}

export type AudienceTargetItem = {
  id: SupportedAudienceTarget | 0
  name: string
  label: string
  title: string
  longTitle: string
}

export type SupportedAudienceTarget = AudienceTargetId.HealthcareProvider | AudienceTargetId.PatientConsumer

export const audienceTargetMap: Partial<Record<AudienceTargetId, AudienceTargetItem>> = {
  [AudienceTargetId.HealthcareProvider]: {
    id: AudienceTargetId.HealthcareProvider,
    name: 'HealthcareProvider',
    label: 'HCP',
    title: 'Unique HCPs',
    longTitle: 'Healthcare Provider (HCPs)',
  },
  [AudienceTargetId.PatientConsumer]: {
    id: AudienceTargetId.PatientConsumer,
    name: 'PatientConsumer',
    label: 'DTC',
    title: 'Unique DTCs',
    longTitle: 'Consumers (DTC)',
  },
  // [AudienceTargetEnum.ProfessionalNonHcp]: {
  //   id: AudienceTargetEnum.ProfessionalNonHcp,
  //   name: 'ProfessionalNonHcp',
  //   label: 'Total Professionals',
  //   title: 'Unique non HCPs',
  //   longTitle: 'Professionals (non HCPs)',
  // },
} satisfies Record<SupportedAudienceTarget, AudienceTargetItem>

export const testExternalAccountId = '1'

export const channelsV1Map: Record<AudienceExternalAccount['channel'], string> = {
  'lasso': 'Lasso',
  'twitter': 'Twitter',
  'facebook-instagram': 'Facebook/Instagram',
  'linked-in': 'LinkedIn',
  'tik-tok': 'TikTok',
  'snapchat': 'Snapchat',
  'liveramp': 'Live Ramp',
  'external-dsp': 'Xandr Curate',
}
