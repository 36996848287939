<template>
  <Box
    py="1.5" px="4" flex
    minHeight="36px"
    spaceX="2"
    class="hover:bg-base-300 cursor-pointer"
    :class="{ 'bg-base-300': active }"
    alignItems="center"
    @click="emits('click')"
  >
    <Box>
      <Chip size="2xs" variant="outlined" :label="audience.id" />
    </Box>

    <Box v-if="!hideCpm">
      <Chip size="2xs" variant="outlined">
        <AudienceCpm :audience="audience" />
      </Chip>
    </Box>

    <Typography variant="body2" color="textPrimary">
      <Highlight :label="audience.name" :highlight="highlight" />
    </Typography>
    <Box v-if="audience.hasSegmentationFilter" flex alignItems="center" mr="1">
      <Tooltip hover>
        <Box flex alignItems="center">
          <Icon icon="groups" variant="outlined" color="primary" />
        </Box>
        <template #content>
          <Typography variant="caption1">
            Demographic Filters Enabled
          </Typography>
        </template>
      </Tooltip>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { Box, Chip, Highlight, Icon, Tooltip, Typography } from '@lasso/luikit'

import AudienceCpm from '../AudienceCpm.vue'

import { AdGroupAudience } from '../types'
import { useCardAudiences } from '../useCardAudiences'

defineProps<{
  audience: AdGroupAudience
  active: boolean
  highlight: string
}>()

const emits = defineEmits<{
  click: []
}>()

const { hideCpm } = useCardAudiences()!
</script>
