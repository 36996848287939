<template>
  <Box v-if="triggerEngagementId === AdGroupTriggerEngagement.CUSTOM_DYNAMIC">
    <Box
      v-for="(item, index) in engagements"
      :key="item.id"
      class="relative"
    >
      <Box
        flex
        wrap="wrap"
        direction="row"
        alignItems="center"
        class="triggers-card"
      >
        <Box flex wrap="wrap" alignContent="center" spaceX="4" spaceY="4">
          <Box pt="2">
            <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
              user who
            </Typography>
          </Box>
          <FormControl
            :id="getId(`lassoTrigger.engagements[${index}].action`)"
            v-slot="{ error, id }"
            hideLabel
            :fullWidth="false"
            width="160px"
          >
            <Box width="160px">
              <Select
                :id="id"
                :modelValue="item.action"
                :options="actionOptions"
                width="160px"
                placeholder="Select"
                :error="error"
                :name="id"
                @update:modelValue="updateEngagement(index, { action: $event })"
              />
            </Box>
          </FormControl>
          <Box pt="2">
            <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
              the source ad
            </Typography>
          </Box>
          <FormControl
            :id="getId(`lassoTrigger.engagements[${index}].quantity`)"
            v-slot="{ id }"
            hideLabel
            :fullWidth="false"
            width="160px"
          >
            <Box width="160px">
              <InputText
                :id="id"
                :modelValue="item.quantity"
                :mask="numberMask"
                :name="id"
                @update:modelValue="updateEngagement(index, { quantity: $event })"
              />
            </Box>
          </FormControl>
          <Box pt="2">
            <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
              times in
            </Typography>
          </Box>
          <FormControl
            :id="getId(`lassoTrigger.engagements[${index}].periodValue`)"
            v-slot="{ id }"
            hideLabel
            :fullWidth="false"
            width="160px"
          >
            <Box width="160px">
              <InputText
                :id="id"
                :modelValue="item.periodValue"
                :mask="numberMask"
                :name="id"
                @update:modelValue="updateEngagement(index, { periodValue: $event })"
              />
            </Box>
          </FormControl>
          <FormControl
            :id="getId(`lassoTrigger.engagements[${index}].period`)"
            v-slot="{ error, id }"
            hideLabel
            :fullWidth="false"
            width="160px"
          >
            <Box width="160px">
              <Select
                :id="id"
                :modelValue="item.period ?? undefined"
                :options="timePeriodOptions"
                width="160px"
                placeholder="Select"
                :error="error"
                :name="id"
                @update:modelValue="updateEngagement(index, { period: $event ?? null })"
              />
            </Box>
          </FormControl>
          <ButtonIcon v-if="canDeleteEngagement" icon="close" class="engagement-delete" @click="removeEngagement(index)" />
        </Box>
      </Box>

      <Box v-if="canDeleteEngagement && index === 0" width="80px" py="4">
        <Select
          :id="getId('lassoTrigger.logicCondition')"
          v-model="logicCondition"
          :options="logicConditionsOptions"
          width="80px"
          name="logicCondition"
        />
      </Box>
    </Box>

    <Box v-if="canAddEngagement" mt="6">
      <AddLink @click="addEngagement()">
        Engagement
      </AddLink>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  AddLink,
  Box,
  ButtonIcon,
  FormControl,
  InputText,
  Select,
  SelectOptionType,
  Typography,
} from '@lasso/luikit'
import { AdGroupTriggerEngagement } from '@lasso/api-activation/activation'
import { objValues } from '@lasso/shared/utils'
import { numberMask } from '@lasso/shared/consts'

import { useCardAudiences } from '../useCardAudiences'
import {
  AudienceEngagementAction,
  AudienceLogicConditions,
  AudienceTimePeriod,
} from '../types'

const timePeriodOptions = [
  {
    label: 'Day',
    value: AudienceTimePeriod.Day,
  },
]

const {
  triggerEngagementId,
  engagements,
  logicCondition,
  addEngagement,
  updateEngagement,
  removeEngagement,
  getId,
} = useCardAudiences()!

const actionMap: { [K in AudienceEngagementAction]: SelectOptionType<AudienceEngagementAction> } = {
  [AudienceEngagementAction.Viewed]: {
    value: AudienceEngagementAction.Viewed,
    label: 'Viewed',
  },
  [AudienceEngagementAction.Clicked]: {
    value: AudienceEngagementAction.Clicked,
    label: 'Clicked',
  },
}

const actionOptions = objValues(actionMap)

const logicConditionsMap: { [K in AudienceLogicConditions]: SelectOptionType<AudienceLogicConditions> } = {
  [AudienceLogicConditions.AND]: {
    value: AudienceLogicConditions.AND,
    label: 'AND',
  },
  [AudienceLogicConditions.OR]: {
    value: AudienceLogicConditions.OR,
    label: 'OR',
  },
}

const logicConditionsOptions = objValues(logicConditionsMap)

const canDeleteEngagement = computed(() => engagements.value.length > 1)
const canAddEngagement = computed(() => engagements.value.length < 2)
</script>

<style scoped>
.triggers-card {
  @apply border border-divider rounded-xl py-[29px] px-6 gap-4 pr-10;
}

.engagement-delete {
  @apply absolute right-4 top-4;
}
</style>
