import { AdGroupTriggerEngagement, AudienceType } from '@lasso/api-activation/activation'
import { Asserts, TypeOf } from 'yup'

import {
  buildCardAudiencesSchema,
} from './buildCardAudiencesSchema'

export type CardAudienceFormInput = {
  audience: AudienceFormInput
  isLassoTrigger: boolean
  lassoTrigger: LassoTriggerInput
}

export type AudienceSchema = ReturnType<typeof buildCardAudiencesSchema>['audience']
export type AudienceFormInput = TypeOf<AudienceSchema>
export type AudienceFormOutput = Asserts<AudienceSchema>

export type LassoTriggerSchema = ReturnType<typeof buildCardAudiencesSchema>['lassoTrigger']
export type LassoTriggerInput = TypeOf<LassoTriggerSchema>
export type LassoTriggerOutput = Asserts<LassoTriggerSchema>

export type AdGroupAudience = {
  id: number
  name: string
  uniqueRecords?: number
  audienceType: AudienceType
  audienceTargetId: string
  createdDate: string
  displayCpm: number
  socialCpm: number
  groupId: number
  excluded?: boolean
  hasSegmentationFilter: boolean
  matchedDeviceRecords?: number // active reach
}

export type AudienceEngagement = {
  id: string
  action: AudienceEngagementAction | string
  quantity: number | null
  periodValue: number | null
  period: AudienceTimePeriod | null
}

export enum AudienceTimePeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export type AudienceAccountType = 'advertiser' | 'marketer'

export type AudienceExternalAccount = {
  accId: string
  accType: AudienceAccountType
  channel: string
}

export type AudienceAccount = {
  id: string
  type: AudienceAccountType
}

export enum AudienceEngagementAction {
  Viewed = 'Viewed',
  Clicked = 'Clicked',
}

export enum AudienceLogicConditions {
  AND = 'AND',
  OR = 'OR',
}

export type TargetingGroup = {
  groupId: number | null
  items: Array<TargetingGroup | TargetingItem>
  type: 'list'
  op: 'and' | 'or'
}

export type TargetingItem = AdGroupAudience & {
  type: 'item'
  items?: Array<TargetingGroup | TargetingItem>
}

export type SupportedAdGroupTriggerEngagement =
    | AdGroupTriggerEngagement.VIEW
    | AdGroupTriggerEngagement.CLICK
    | AdGroupTriggerEngagement.CUSTOM_DYNAMIC
