<template>
  <span>
    {{ formatNumber(buildAudienceCpm(audience, isDataOnly), FormatEnum.Money2) }}
  </span>
</template>

<script setup lang="ts">
import { FormatEnum, formatNumber } from '@lasso/shared/utils'

import { buildAudienceCpm } from './utils'
import { AdGroupAudience } from './types'
import { useCardAudiences } from './useCardAudiences'

defineProps<{
  audience: AdGroupAudience
}>()

const { isDataOnly } = useCardAudiences()!
</script>
