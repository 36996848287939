<template>
  <Box v-if="item.type === 'list'">
    <Box
      v-if="!isFirst"
      flex
      alignItems="center"
      justify="center"
      py="2"
    >
      <Box flex alignItems="center">
        <Chip color="secondary" size="2xs" variant="outlined" :label="op.toUpperCase()" />
      </Box>
    </Box>
    <Paper
      color="base"
      variant="filled"
      p="2"
      my="2"
      class="!bg-base-200"
      rounded="xl"
      shadow="none"
      :data-role="dataRole"
    >
      <Box flex col alignItems="center" spaceX="2">
        <slot />
      </Box>
    </Paper>
  </Box>
  <Box v-else>
    <Box
      v-if="!isFirst"
      flex
      alignItems="center"
      justify="center"
      py="2"
    >
      <Box flex alignItems="center">
        <Chip color="secondary" size="2xs" variant="outlined" :label="op.toUpperCase()" />
      </Box>
    </Box>
    <Paper
      color="base"
      variant="filled"
      class="!bg-base-200"
      rounded="xl"
      shadow="none"
      :data-role="dataRole"
    >
      <Paper variant="filled" color="base" px="2" py="4" rounded="xl" class="bg-base-200!">
        <Box flex justify="between" alignItems="center" spaceX="2">
          <Icon icon="drag_indicator" class="cursor-move" :style="{ visibility: dataRole ? 'visible' : 'hidden' }" />

          <Box flexGrow="flex-1">
            <!-- TODO: use Divider once it's properly implemented -->
            <Grid container class="divide-y divide-divider divide-dashed" flow="row">
              <Box pb="2">
                <Box flex alignItems="center" justify="between">
                  <Box flex alignItems="center" spaceX="2">
                    <Chip size="2xs" variant="outlined" :label="item.id" />
                    <Chip v-if="!hideCpm" size="2xs" variant="outlined">
                      <AudienceCpm :audience="item" />
                    </Chip>

                    <Typography variant="body2">
                      {{ item.name }}
                    </Typography>
                  </Box>

                  <Box flex alignItems="center" spaceX="2">
                    <Toggle
                      v-if="targetable"
                      v-model="included"
                      :readonly="targetDisabled"
                      size="xs"
                      color="success"
                      offColor="error"
                    />

                    <ButtonIcon :disabled="isDeleteDisabled" icon="close" @click="emit('removeItem')" />
                  </Box>
                </Box>
              </Box>

              <Box pt="2" flex class="divide-x divide-divider divide-dashed">
                <Box v-if="item.matchedDeviceRecords" flex alignItems="center" spaceX="2" pr="4">
                  <Typography variant="caption1" color="textSecondaryLight" uppercase>
                    Active reach:
                  </Typography>
                  <Typography variant="caption2" color="textSecondaryLight" uppercase>
                    {{ formatNumber(item.matchedDeviceRecords, FormatEnum.Dec1) }}
                  </Typography>
                </Box>

                <Box flex alignItems="center" spaceX="2" :pr="item.matchedDeviceRecords ? '4' : undefined" pl="4">
                  <Typography variant="caption1" color="textSecondaryLight" uppercase>
                    Target:
                  </Typography>
                  <Typography variant="caption2" color="textSecondaryLight" uppercase>
                    {{ getAudienceTarget(item.audienceTargetId as unknown as AudienceTargetId)?.label }} -
                    {{ item.audienceTargetId }}
                  </Typography>
                </Box>

                <Box flex alignItems="center" spaceX="2" px="4">
                  <Typography variant="caption1" color="textSecondaryLight" uppercase>
                    Audience type:
                  </Typography>
                  <Typography variant="caption2" color="textSecondaryLight" uppercase>
                    {{ getAudienceTypeLabel(item.audienceType) }}
                  </Typography>
                </Box>

                <Box flex alignItems="center" spaceX="2" pl="4">
                  <Typography variant="caption1" color="textSecondaryLight" uppercase>
                    Created:
                  </Typography>
                  <Typography variant="caption2" color="textSecondaryLight" uppercase>
                    {{ formatDate(item.createdDate, 'monthYearAbbr', 'EST') }}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <slot />
    </Paper>
  </Box>
</template>

<script setup lang="ts">
import { Box, ButtonIcon, Chip, DraggableNestedItemProps, Grid, Icon, Paper, Toggle, Typography } from '@lasso/luikit'
import { computed } from 'vue'
import { FormatEnum, formatDate, formatNumber } from '@lasso/shared/utils'
import { AudienceType } from '@lasso/api-activation/activation'
import { camelCase } from 'lodash-es'

import { getAudienceTarget } from './utils'
import { AudienceTargetId } from './consts'
import AudienceCpm from './AudienceCpm.vue'
import { TargetingGroup, TargetingItem } from './types'
import { useCardAudiences } from './useCardAudiences'

const props = defineProps<(DraggableNestedItemProps<TargetingGroup> | DraggableNestedItemProps<TargetingItem>) & {
  draggable: boolean
  targetable: boolean
  targetDisabled: boolean
  disabled: boolean
}>()

const emit = defineEmits<{
  'update:item': [TargetingGroup | TargetingItem]
  removeItem: []
}>()

const { getInitialAudience, hideCpm } = useCardAudiences()!

const op = computed(() => {
  return props.parentItem.type === 'list' ? props.parentItem.op : ''
})

const dataRole = computed(() => props.draggable ? 'draggable' : undefined)

const included = computed({
  get: () => props.item.type === 'item' ? !props.item.excluded : false,
  set: (value) => {
    if (props.item.type === 'item') {
      emit('update:item', { ...props.item, excluded: !value, items: undefined })
    }
  },
})

const isDeleteDisabled = computed(() => {
  if (props.item.type !== 'item') {
    return false
  }

  const initialAudience = getInitialAudience(props.item.id)

  return Boolean(initialAudience) && props.disabled
})

const getAudienceTypeLabel = (audienceType: AudienceType): string => {
  switch (audienceType) {
    case AudienceType.UNIVERSAL_PIXEL_AUDIENCE_CONVERSION:
      return 'Universal Pixel'
    default:
      return camelCase(audienceType)
  }
}
</script>
